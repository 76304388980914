<template>
    <div>
        <page-header-layout>
            <div class="main-page-content">
                <el-row :gutter="48">
                    <el-col class="el-col-sm-24 el-col-md-10 el-col-lg-10 el-col-offset-1 ">
                        <div class="el-col-sm-pull-2">
                            <el-button type="primary" size="mini" @click="onSelectQuestion">选文章</el-button>
                        </div>
                        <div class="el-col-sm-pull-2">
                            <el-button type="danger"
                                       size="mini"
                                       @click="onDeleteArticleClick"
                                       :disabled="articleIds.length == 0"
                            >
                                批量删除
                            </el-button>
                        </div>
                    </el-col>

                    <el-col class="el-col-sm-24 el-col-md-24 el-col-lg-24" style="margin-top: 20px">
                        <label class="color-primary">已选文章</label>
                        <ape-table ref="existsApeTable"
                                   :data="articleAddList"
                                   :columns="articleColumns"
                                   :loading="loadingStatus"
                                   :pagingData="existsPagingData"
                                   @selectList="handleSelectionChange"
                                   @switchPaging="switchPaging"
                                   highlight-current-row>

                            <el-table-column
                                    slot="first-column"
                                    width="80"
                                    type="selection"
                                    align="center">

                            </el-table-column>

                            <el-table-column
                                    v-if="buttonType== 'icon'"
                                    label="操作">
                                <template slot-scope="scope">
              <span>
                <el-tooltip effect="dark" content="删除" placement="top-start">
                  <span>
                      <el-button type="danger"
                                 size="mini"
                                 v-if="userPermissions.indexOf('test_paper_delete') != -1"
                                 icon="el-icon-delete"
                                 @click="deleteButton(scope.row.id)"
                      ></el-button>
                  </span>
                </el-tooltip>
              </span>
                                </template>
                            </el-table-column>
                            <el-table-column
                                    label="操作"
                                    v-if="buttonType== 'text'"
                            >
                                <template slot-scope="scope">
              <span>

                <el-tooltip effect="dark" content="删除" placement="top-start">
                  <span>
                    <!--<el-popover-->
                      <!--v-if="userPermissions.indexOf('test_test_paper_delete') != -1"-->
                      <!--placement="top"-->
                      <!--width="150"-->
                      <!--v-model="scope.row.visible">-->
                      <!--<p>确定要删除记录吗？</p>-->
                      <!--<div style="text-align: right; margin: 0;">-->
                      <!--<el-button type="text" size="mini" @click="scope.row.visible=false">取消</el-button>-->
                      <!--<el-button type="danger" size="mini" @click="deleteButton(scope.row.id)">确定</el-button>-->
                      <!--</div>-->
                      <!--<el-button slot="reference" type="danger" size="mini">删除</el-button>-->
                      <!--</el-popover>-->

                        <el-button type="danger"
                                   size="mini"
                                   v-if="userPermissions.indexOf('test_paper_delete') != -1"
                                   icon="el-icon-delete"
                                   @click="deleteButton(scope.row.id)"
                        ></el-button>
                  </span>
                </el-tooltip>
              </span>
                                </template>
                            </el-table-column>
                        </ape-table>

                    </el-col>

                </el-row>
            </div>
        </page-header-layout>

        <ArticlePickDialog
                ref="articleDialog"
                :dialogData="dialogDate"
                :articleDialogData="articleDialogData"
                @dialog-close="handleArticleDialogClose"
                @dialog-confirm="handleArticleDialogConfirm"
        ></ArticlePickDialog>
    </div>

</template>

<script>
    import PageHeaderLayout from '@/layouts/PageHeaderLayout'
    import ApeTable from '@/components/ApeTable';
    import elTableInfiniteScroll from 'el-table-infinite-scroll';
    import ChangeQuestionBank from '@/components/ChangeQuestionBank'
    import {mapGetters} from 'vuex'
    import ArticlePickDialog from './ArticlePickDialog'

    const NO_DEL = "no_del";
    const DEL = "del";
    const IS_SUCCESS = "ok";
    export default {
        components: {
            PageHeaderLayout,
            ApeTable,
            ChangeQuestionBank,
            ArticlePickDialog
        },
        directives: {
            'el-table-infinite-scroll': elTableInfiniteScroll
        },
        data() {
            return {

                loadingStatus: true,
                questionBankQuestionList: [],
                summaryPagingData: {
                    offset: undefined
                },

                existsPagingData: {
                    is_show: true,
                    layout: 'total, sizes, prev, pager, next, jumper',
                    total: 0,
                    offset: 0, // 分页的offset,序号列使用
                },

                summaryList: [
                    {
                        question_category: "单选题",
                        question_count: 100,
                        total_score: 200
                    },
                    {
                        question_category: "不定项选择题",
                        question_count: 100,
                        total_score: 200
                    },
                    {
                        question_category: "案例分析题",
                        question_count: 100,
                        total_score: 200
                    },
                    {
                        question_category: "论述题",
                        question_count: 100,
                        total_score: 200
                    },
                ],

                //题目数据
                articleColumns: [
                    {
                        title: '标题',
                        value: [
                            {label: 'ID：', value: 'id'},
                            {label: '标题：', value: 'title_alias', value_alias: 'title'},
                            {label: '作者：', value: 'author_name'},
                        ],
                    },
                    {
                        title: '封面',
                        type: 'image',
                        value: 'cover_pic_url',
                    },
                    {
                        title: '缩略图',
                        type: 'image',
                        value: 'thumb_pic_url',
                    },
                    {
                        title: '发布时间',
                        value: 'created_at',
                    }
                ],

                //分页信息
                pagingData: {
                    is_show: true,
                    layout: 'total, sizes, prev, pager, next, jumper',
                    total: 0,
                    offset: 0, // 分页的offset,序号列使用
                },

                questionList: [],
                modelVisible: false,
                isShowCard: false,
                tableSelectQuestionList: [],
                tableSelectList: [],
                tableData: [],
                totalPage: 1,
                cacheId: "",
                //修改分数和顺序
                modifyData: {
                    score: "",
                    order: ""
                },
                //过滤题目数组
                checkedQuestionList: [],
                totalCount: null,
                page: 1,
                //已经新增的表
                articleAddList: [],
                cId: "",
                // //分页信息
                pagingInfo: {
                    page_size: 10,
                    current_page: 1
                },

                //搜索条件
                searchCondition: {},
                searchParams: {},
                isSearch: false,
                qtqId: "",
                summaryQuestionList: [],
                qbId: "",


                //选择文章对话框配置
                dialogDate: {
                    visible: false,
                    title: "选择文章",
                    width: '50%'
                },

                articleDialogData: {
                    id: ""
                },

                stcId: "",

                //选中文章
                articleIds:[]
            }
        },
        mounted() {
            this.cacheId = new Date().getTime();
            this.stcId = this.$route.params.c_id;
            this.qbId = this.$route.params.qb_id;
            this.resetArticleResourceList(DEL);
        },
        methods: {


            //删除已选题目
            deleteButton(id) {

                this.$confirm('是否删除该已选文章?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async() => {
                    let data = {
                        stc_id:this.stcId,
                        ids: [id],
                    };

                    let info = await this.$api.deleteSpecialTopicResourceList(data);
                    if (info) {
                        this.$nextTick(() => {
                            this.resetArticleResourceList(DEL);
                            this.$message.success("删除成功");
                        })
                    } else {
                        this.$message.error("删除失败")
                    }
                })
            },

            //弹框
            onSelectQuestion() {
                this.dialogDate.visible = true;
                this.articleDialogData.id = this.stcId;
                this.$nextTick(()=>{
                    this.$refs['articleDialog'].resetData();
                })
            },

            //以选文章
            async resetArticleResourceList(type) {
                this.loadingStatus = true;
                let pagingInfo = this.$refs['existsApeTable'].getPagingInfo(type);
                if (!this.stcId) {
                    this.$message("分类ID不存在");
                    return;
                }

                let data = {
                    page_size: pagingInfo.page_size,
                    current_page: pagingInfo.current_page,
                    stc_id: this.stcId
                };

                let {list, pages} = await this.$api.selectSpecialTopicCategoryResourceList(data);

                this.$nextTick(() => {

                    this.articleAddList = list;

                });
                this.existsPagingData.total = pages.total;
                this.existsPagingData.offset = pages.offset;
                this.loadingStatus = false
            },

            //切换页码
            async switchPaging() {
                this.resetArticleResourceList()
            },

            //------选择文章相关方法--------
            handleArticleDialogClose() {
                this.dialogDate.visible = false;
            },

            //文章弹框点击
            async handleArticleDialogConfirm(formData) {
                let formDataLen = formData.length;
                let articleLen = this.articleAddList.length;

                if(formDataLen + articleLen > 5){
                    this.$message.error("选择的文章最多不能超过五篇");
                    return;
                }

                if(!this.stcId){
                    this.$message("分类不能为空");
                    return;
                }

                let params = {
                    stc_id: this.stcId,
                    articles: formData
                };


                let info = await this.$api.selectSpecialTopicCategoryArticle(params);
                if (info) {
                    this.$message.success("添加成功");
                } else {
                    this.$message.error("添加失败");
                    return;
                }

                //刷新列表
                // this.$refs['articleDialog'].resetData();
                this.resetArticleResourceList(DEL);
                this.dialogDate.visible = false;
            },

            //获取所选择的文章列表
            handleSelectionChange(list){
                this.articleIds = [];
                for(let article of list){
                    this.articleIds.push(article.id);
                }
            },

            //批量删除文章
            onDeleteArticleClick(){
                if(this.articleIds.length <= 0){
                    return;
                }


                this.$confirm('是否删除已选文章?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(async() => {
                    let data = {
                        stc_id:this.stcId,
                        ids: this.articleIds
                    };

                    let info = await this.$api.deleteSpecialTopicResourceList(data);
                    if (info) {
                        this.$nextTick(() => {
                            this.resetArticleResourceList(DEL);
                            this.$message.success("删除成功");
                        })
                    } else {
                        this.$message.error("删除失败")
                    }
                })
            },
        },
        computed: {
            ...mapGetters(['userPermissions', 'buttonType']),
        }
    }
</script>

<style lang="stylus" scoped>
    .color-primary
        color #1890ff

    .text-over-hide {
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
    }

    .flex-layout {
        display flex
    }

    .margin-top-20 {
        margin-top 20px
    }
</style>